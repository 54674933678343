.custom-col {
    flex: 0 0 20%; /* Each column takes up 20% of the row */
    max-width: 20%; /* Restrict max width to 20% */
  }

  .bg-light-main{

    background: linear-gradient(100.3deg, rgba(255, 240, 162, 0.34) 15.13%, rgba(254, 183, 32, 0.34) 78.06%);
.container{
    background: #FEB720;
    padding: 30px;
   
    top: 2424px;
    left: 54px;
    gap: 0px;
    border-radius: 18px;
    
    >div>h2{
        font-family: Manrope;
font-size: 24px;
font-weight: 800;
line-height: 32.78px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
 
    }
}
  }

  .card-body>div>p{
    font-family: Manrope;
font-size: 14px;
font-weight: 400;
line-height: 19.12px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

  }
  .card-body>div>h5{
    font-family: Manrope;
    font-size: 18px;
    font-weight: 600;
    line-height: 24.59px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    

  }
  .bg-yellow{
    background: #FEB720 !important;
  }.border-none{
    border: none !important;
  }.bg-white{
    background-color: white !important;
  }.text-dark{
    color: black !important;
  }.bg-dark{
    background-color: black !important;
  }.py-107{
    padding: 107px 0px !important;
  }

   
  
  /* Media query for extra small screens (e.g., mobile) */
  @media (max-width: 575.98px) {
    .custom-col {
      flex: 0 0 50%; /* Stack columns on smaller screens */
      max-width: 50%;
    }
  }
  
  /* Media query for small screens (e.g., tablets) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    .custom-col {
      flex: 0 0 50%; /* Two columns per row */
      max-width: 50%;
    }
  }
  
  /* Media query for medium screens */
  @media (min-width: 768px) and (max-width: 991.98px) {
    .custom-col {
      flex: 0 0 33.33%; /* Three columns per row */
      max-width: 33.33%;
    }
  }
  
  /* Media query for large screens */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .custom-col {
      flex: 0 0 25%; /* Four columns per row */
      max-width: 25%;
    }
  }
  
  /* Media query for extra large screens (default 20%) */
  @media (min-width: 1200px) {
    .custom-col {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
  
  