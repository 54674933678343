.layout-containter{
   padding: 65px 40px; 


   .layout-main-section{
     .left-section{
        min-height: 612px;
        .sub-sec{
            height: 100%;
            box-shadow: 2px 2px 12px 0px #00000040;
border-radius: 15px !important;
padding: 20px;
div{
    height: 100%;
    h2{
        margin-bottom: 55px;
    }

    .image-div{
        max-height: 481px;
    }
}
        }
     }

     .right-section>div{
        padding: 35px;
     }
   }
}


.item-div{
    background: #CCE8CB;
    display: flex;
    margin: 26px 0px;
    align-items: center;
padding: 9px 30px;
border-radius: 8px;
box-shadow: 7px 6px var(--sds-size-depth-200) -4px #00476B30;
.img-icon{
    width: 34px;
    height: 34px;
    margin-right: 18px;
    background: #9BB89A;
border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 60%;
    }
}
}


@media screen and (max-width: 765px) {
    .left-section{
        min-height: 275px !important;}
    .image-div {
        max-height: 200px !important;
    }
    .sub-sec{
        div{
            h2{
                margin-bottom: 15px !important;
            }
        }
    }
}



.right-section>div>h5{
    font-family: Manrope;
font-size: 24px;
font-weight: 800;
line-height: 32.78px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #090808;

}
.right-section>div>p{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 300;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;

}

.bg-bule{
    background: #8CC6D7 !important;

}

.bg-bule-dark{
    background: #5B8F9E    !important;

}
.bg-prpul{
     background: #9C95C7!important;

}

.bg-prpul-dark{
    background: #79749D    !important;

}
.bg-Lilac{
    background: #C4A1C6 !important;

}

.bg-Lilac-dark{
    background: #A774AA    !important;

}

.bg-red{
    background: #ECC0CA !important;

}

.bg-red-dark{
    background: #BD8391 !important;


}

.bg-pink{
    background: #F5D3D9 !important;

}

.bg-pink-dark{
    background:  #DE98A4 !important;

}