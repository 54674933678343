
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

@media screen and (min-width: 600px) {
    .new_UI_landing{
        background-image: url("../assets/background_image.png");
        height: 140vh;
        width: 100vw;
        background-size: cover;
    }
    .alignRightItems{
        float: right !important;
        display: flex;
        margin-right: 20px;
    }
    .categories-hover-div{
        height: 110px;
        width: 105px;
        color: #124351;
        position: absolute;
        background-color: #fff;
        margin-left: 12.2em;
        margin-top: 9.2em;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        z-index: 999999999999999;
        padding-top: 10px;
        padding-left: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    .nav-link_new{
        color: #124351;
        text-align: center !important;
        line-height: 30px;
        font-size: 14px;
    }
    .nav-link_new:hover{
        opacity: 0.9;
        color: #124351;
    }
    .location-bg-div{
        background-repeat: no-repeat, repeat;
        background-position: left top;
        background-image: url("../assets/location.png");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 85vh;
        object-fit: contain;
        display: block;
    }
    .visit-site{
        width: 160px;
        height: 40px;
        border: 0px solid transparent;
        color: white;
        font-size: 16px;
        font-family: "Poppins";
        background-color: #d3ad40;
        border-radius: 45px;
        margin-top: 70%;
    }
    .visit-site:hover{
        background-color: #b28e2b;
    }
    p.specification_txt{
        font-size: 0.9em;
        font-family: 'Poppins', sans-serif;
        color: #3A3636;
        text-align: right;
    }
    .new_UI_iframe_mobile{
        display: none;
    }
    .new_UI_iframe{
        display: block;
        margin-top: -60px;
        z-index: 9999999;
        position: absolute;
        margin-left: 30px;
    }
    .highlight_img{
        height: 320px;
        margin-top: 5%;
        object-fit: contain;
    }
    .floorplan_img{
        width: 100%;
        object-fit: contain;
        margin-top: 1%;
    }
    .snapshot_img{
        width: 100%;
        object-fit: contain;
        margin-top: 1%;
    }
    .new_UI_highlights_right{
        background-image: url("../assets/highlight_bg.png");
        height: 60vh;
        width: 100%;
        background-size: cover;
        margin-left: 15px;
    }
    .new_UI_landing_inner{
        background: linear-gradient(99.32deg, rgba(6, 41, 51, 0.55) 48.37%, rgba(92, 150, 141, 0.132) 60.48%, rgba(83, 165, 125, 0.1045) 75.52%, rgba(2, 123, 45, 0.55) 88.68%);
        height: 140vh;
        width: 100vw;
        background-size: cover;
        padding-top: 15%;
    }
    .logos_margin{
        margin-right: 7%;
    }
    .new_UI_landing_2{
        background-image: url("../assets/background_image_2.png");
        height: 140vh;
        width: 100vw;
        background-size: cover;
    }
    .new_UI_landing_inner_2{
        background: rgba(20, 122, 3, 0.43);
        height: 140vh;
        width: 100vw;
        background-size: cover;
    }
    .new_UI_landing_2_card{
        height: 134px;
        width: 200px;
        background-color: #fff;
        align-items: center;
        justify-content: center;
        display: flex;
        padding-left: 4%;
        padding-right: 4%;
        padding-top: 4.7%;
        margin-bottom: 12%;
        box-shadow: 0px 16px 4px 11px rgba(0, 0, 0, 0.25);
    }
    p.inner_2_txt{
        font-family: 'Poppins', sans-serif;
        font-weight:bolder ;
        font-size: 17px;
        align-items: center;
        text-align: center;
        align-self: center;
        color: #000000;
        display: flex;
        justify-content: center;
    }
    .iframe_shadow{
        background-image: url("../assets/green_shadow.png");
        width: 100%;
        height: 400px;
        border-radius: 10px;
        background-size: cover;
        margin-top: 8%;
    }
    p.new_UI_landing_inner_txt{
        margin-left: 20%;
        font-family: 'Inter';
        font-style: normal;
        font-weight:bolder;
        font-size: 1.6em;
        line-height: 40px;
        color: #FFFFFF;
        font-family: 'Poppins', sans-serif;
    }
    .location-bg-div-mobile{
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .new_UI_landing{
        background-image: url("../assets/background_image.png");
        height: 100vh;
        width: 100vw;
        background-size: cover;
    }
    .categories-hover-div{
        display: none;
    }
    .alignRightItems{
        float: right !important;
        display: flex;
        margin-right: 20px;
    }
    .location-bg-div{
        display: none;
    }
    .location-bg-div-mobile{
        display: block;
    }
    .visit-site{
        width: 160px;
        height: 40px;
        border: 0px solid transparent;
        color: white;
        font-size: 16px;
        font-family: "Poppins";
        background-color: #d3ad40;
        border-radius: 45px;
        margin-top: 7%;
        margin-bottom: 5%;
    }
    .visit-site:hover{
        background-color: #b28e2b;
    }
    .new_UI_iframe_mobile{
        display: block;
    }
    p.specification_txt{
        font-size: 0.7em;
        font-family: 'Poppins', sans-serif;
        color: #3A3636;
        text-align: right;
    }
    .new_UI_iframe{
        display: none;
    }
    .new_UI_highlights_right{
        height: 100%;
        width: 100%;
    }
    .highlight_img{
        height: 320px;
        width: 100%;
        margin-top: 1%;
        object-fit: contain;
    }
    .floorplan_img{
        height: 100%;
        width: 100%;
        object-fit: contain;
        margin-top: 1%;
    }
    .snapshot_img{
        height: 100%;
        width: 100%;
        object-fit: contain;
        margin-top: 1%;
    }
    p.new_UI_landing_inner_txt{
        margin-left: 20%;
        margin-top: 20%;
        font-family: 'Inter';
        font-style: normal;
        font-weight:bolder;
        font-size: 1.6em;
        color: #FFFFFF;
        font-family: 'Poppins', sans-serif;
    }
    .new_UI_landing_inner{
        background: linear-gradient(99.32deg, rgba(6, 41, 51, 0.55) 48.37%, rgba(92, 150, 141, 0.132) 60.48%, rgba(83, 165, 125, 0.1045) 75.52%, rgba(2, 123, 45, 0.55) 88.68%);
        height: 100vh;
        width: 100vw;
        background-size: cover;
        padding-top: 10%;
    }
    .new_UI_landing_2{
        background-image: url("../assets/background_image_2.png");
        height: 100vh;
        width: 100vw;
        background-size: cover;
    }
    .new_UI_landing_inner_2{
        background: rgba(20, 122, 3, 0.43);
        height: 100vh;
        width: 100vw;
        background-size: cover;
    }
    .new_UI_landing_2_card{
        margin-top: 40%;
        height: 84px;
        width: 100%;
        background-color: #fff;
        align-items: center;
        justify-content: center;
        display: flex;
        padding-left: 4%;
        padding-right: 4%;
        padding-top: 13%;
        margin-bottom: 20%;
        margin-left: 10px;
        margin-right: 10px;
        box-shadow: 0px 16px 4px 11px rgba(0, 0, 0, 0.25);
    }
    p.inner_2_txt{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 0.7em;
        text-align: center;
        color: #000000;
        margin-top: 5px;
    }
}

.new_navbar{
    width: 100%;
    height: 11vh;
    background: #124351;
    position: fixed;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding-top: 10px;
    z-index: 99999999;
}

.navbar{
    margin-top: 7px;
}
.inner_2_margins{
    padding: 7%;
    padding-top: 10%;
}

.new_UI_highlights{
    padding: 9%;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-top: 5%;
}
.marginLefts{
    padding: 9%;
    padding-top: 2%;
    padding-bottom: 1%;
}
p.new_UI_highlights_title{
    color: #3A3636;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 30px;
}
p.new_UI_highlights_txt{
    color: #3A3636;
    font-size: 21px;
    font-family: 'Poppins', sans-serif;
}

.location_img{
    width: 100%;
    object-fit: contain;
    margin-top: 1%;
}
.specification_card{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 13em;
    width: 95%;
    background-color: #fff;
    margin-bottom: 7%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    padding: 5%;
}
p.specification_title{
    font-size: 1.2em;
    font-weight: bolder;
    font-family: 'Poppins', sans-serif;
    color: #222;
    text-align: right;
}
.specification_img{
    width: 100%;
    height: 9em;
    object-fit: contain;
}
.new_sideBar-mobile{
    background-color: rgba(20, 122, 3, 1);
    margin: 5%;
    margin-top: 25%;
    padding: 7%;
    padding-top: 15%;
    border-radius: 20px;
}
.new_closeIconX{
    float: right;
    cursor: pointer;
    color: rgba(20, 122, 3, 1);
    margin-right: 10%;
    margin-top: 10%;
}
    