@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');


.app {
	display: flex;
	width: 100vh;
	height: 100vh;
	align-items: center;
	justify-content: center;
}
.__proccessof_projects img {
	max-width: 100%;
}
.header_section{
	width: 100%;
height: 166px;
top: 1px;
left: -9px;
gap: 0px;
background: #00476B;
display: flex;
 
border-radius: 35px 35px 0px 0px;



  
.left_side{
	 
	 width: 80%;
 display: flex;
 align-items: center;
 margin-left: 60px;
top: 46px;
left: 50px;
gap: 0px;

 .main_div{

	 
	 
	 h1{
		 font-family: "Manrope", sans-serif;
		 font-size: 38px;
		 font-weight: 800;
		 line-height: 54.64px;
		 text-align: left;
		 text-underline-position: from-font;
		 text-decoration-skip-ink: none;
		 color: #FFFFFF;
		 
		}
		p{
			font-family: "Manrope", sans-serif;
			font-size: 20px;
			font-weight: 400;
			line-height: 27.32px;
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #FFFFFF;
		}

}
}
.right_side{
	display: flex;
	justify-content: end;
	margin-right: 61px;
	align-items: center;
	width: 20%;
 >div{
	width: 60px;
height: 60px;
top: 62px;
left: 1321px;
gap: 0px;
border-radius: 8px ;

background: #FFFFFF;
box-shadow: 2px -3px 29px 10px #E1F3FA52;
 align-content: center; 
 text-align: center;
 }

}

}


















































@media screen and (max-width: 992px) {
	.header_section{
		width: 100%;
	height: 130px;
border-radius: 0px;
	.left_side{
	 
		 
		.main_div{
	   
			
			
			h1{
				 
				font-family: Manrope;
	font-size: 25px;

}

p{
	font-size: 14px;
}
}}

}



.right_side{
	display: flex;
	justify-content: end;
	margin-right: 12px;
	align-items: center;
	width: 10%;
 >div{
	width: 35px;
height: 35px;
top: 62px;
left: 1321px;
gap: 0px;
border-radius: 4px ;

background: #FFFFFF;
box-shadow: 2px -3px 29px 10px #E1F3FA52;
  align-items: center;
  display: flex;
  justify-content: center;
 text-align: center;
 img{
	width: 30px;
	height: 30px;
 }
 }

}



  }
/* Media Query for Responsive Design */
@media screen and (max-width: 550px) {
	.header_section{
		width: 100%;
	height: 83px !important;
border-radius: 0px;

	.left_side{
		margin-left: 12px;
		 width: 90%;
	.main_div{
   
		
		
		h1{
			 
			font-family: Manrope;
font-size: 18px;
font-weight: 700;
line-height: 24.59px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

		   }
		   p{
			font-family: Manrope;
			font-size: 12px;
			font-weight: 400;
			line-height: 16.39px;
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			
		   }
   
   }
}

.right_side{
	display: flex;
	justify-content: end;
	margin-right: 12px;
	align-items: center;
	width: 10%;
 >div{
	width: 19px;
height: 19px;
top: 62px;
left: 1321px;
gap: 0px;
border-radius: 4px ;

background: #FFFFFF;
box-shadow: 2px -3px 29px 10px #E1F3FA52;
  align-items: center;
  display: flex;
  justify-content: center;
 text-align: center;
 img{
	width: 15px;
	height: 15px;
 }
 }

}

}
  }
  