.p-30 {
    padding: 30px 40px !important;
    background-image: linear-gradient(to right, rgb(227, 237, 249), rgb(255, 255, 255));
  }
  
  .border-none {
    border: none !important;
    box-shadow: none !important;
  }
  
  .doc-item {
    max-width: 225px;
    height: 64px;
    border: 0.5px solid #868181;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
  
  .doc_heading {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 800;
    line-height: 32.78px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: black;
  }
  
  .brand_card {
    border: 0.5px solid #d9d9d9 !important;
    background: #fafafa !important;
    border-radius: 8px !important;
  }
  
  section {
    padding: 5px 0px !important;
  }
  
  .right-section {
    padding-left: 20px;
    padding-right: 40px;
  }
  .price_offer{
    width: 316px;
height: 410px;
 border-radius: 13px;
background-image: url(./Color-Triangle-59.png),linear-gradient(#155677e4,#2e7ea6);
background-repeat: no-repeat;
background-size: cover;
padding-top: 31px;


.pricing-div{
  width: 226px;
height: 226px;
border-radius: 50%;
 margin: 0 auto;
background: #E6EDF1;
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
 
.inner-div{
  width: 190px;
height: 190px;
background: #FFFFFF;
border-radius: 50%;
border: 0.4px  ;
 
display: flex;
flex-wrap: wrap;
 flex-direction: column;
 justify-content: center;
}
}
.heading{
  

margin-bottom: 25px;
  h2{
    font-family: Manrope;
font-size: 30px;
font-weight: 800;
line-height: 40.98px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFAFA;

  }

}
}



.last_price{
  text-align: center;
  margin: 10px 0;
  color: #fff;
  span{
    font-family: Manrope;
font-size: 22px;
font-weight: 800;
line-height: 30.05px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FEB720;

  }
}