@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

@media screen and (max-width: 767px) {
    .ad-view {
        margin-left: 0;
        margin-right: 0;
        margin-top: 15%;
        background-color: #fff;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        padding-left: 3%;
        padding-top: 6%;
        padding-right: 3%;
        padding-bottom: 3%;
    }

    .inner-content-div {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        padding-left: 3%;
        padding-top: 3.5%;
        padding-right: 3%;
        background-color: #fff;
    }

    .profile-pic {
        margin-top: -85px;
    }

    .btn-div {
        margin-top: 6%;
    }

    img.progress-img {
        height: 130px;
        width: 130px;
        float: left;
    }

    button.btn-virtual-tour {
        background-color: #C4C4C4;
        border: none;
        color: #000;
        padding: 7px 25px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 5px;
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        margin-top: 4%;
        width: 100%;
        height: 45px;
    }

    button.btn-virtual-date {
        background-color: #fff;
        border: none;
        color: #4A4844;
        padding: 7px 25px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 5px;
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        margin-top: 10%;
        margin-bottom: 3%;
        width: 100%;
        height: 40px;
        border: 2px solid #AE9A9A;
        border-radius: 30px;
    }

    p.project-date {
        margin-top: 6%;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: #fff;
    }

    .sidebar_view {
        display: block;
        position: absolute;
        left: 0;
        top: 2px;
        width: 90%;
    }

    .ads {
        width: unset !important;
        margin: 0 !important;
    }
}

@media only screen and (min-width: 768px) {
    .ad-view {
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 5%;
        margin-bottom: 5%;
        background-color: #fff;
        border-radius: 30px;
        padding-left: 8%;
        padding-top: 5%;
        padding-right: 8%;
        padding-bottom: 5%;
    }

    .btn-div {
        margin-top: 4%;
    }

    .verticalLine {
        border-right: 1px solid #B1AFAF;
    }

    img.progress-img {
        height: 200px;
        width: 200px;
        float: left;
    }

    button.btn-virtual-tour {
        background-color: #C4C4C4;
        border: none;
        color: #000;
        padding: 7px 25px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 5px;
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        margin-top: 4%;
        width: 100%;
        height: 45px;
    }

    button.btn-virtual-date {
        background-color: #fff;
        border: none;
        color: #4A4844;
        padding: 7px 25px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 5px;
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        margin-top: 3%;
        margin-bottom: 4%;
        width: 100%;
        height: 40px;
        border: 2px solid #AE9A9A;
        border-radius: 30px;
    }

    .scroll::-webkit-scrollbar {
        display: none;
        /* for Chrome, Safari, and Opera */
    }

    p.project-date {
        margin-top: 4%;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: #fff;
        margin-left: 1.5%;
    }

    .sidebar_view {
        position: absolute;
        float: right;
        height: 314.7vh;
    }
}

.__builderinfo_wrap.__builder_items {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ads {
    max-height: 100%;
}

.shadow {
    border: 2px solid #fff;
    -moz-box-shadow: 0px 6px 5px #ccc;
    -webkit-box-shadow: 0px 6px 5px #ccc;
    box-shadow: 0px 6px 5px #ccc;
    -moz-border-radius: 190px;
    -webkit-border-radius: 190px;
    border-radius: 190px;
}

.sidebar {
    float: right;
    margin-right: 1.5%;
    margin-top: 1%;
    cursor: pointer;
}

.message-txt {
    padding-top: 4%;
    font-family: 'Roboto', sans-serif;
}


button.contact-btn {
    background-color: #3D1F68;
    border: none;
    color: #fff;
    padding: 7px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    margin-left: 1%;
    border: 2px solid #3D1F68;
}

th {
    font-family: 'Roboto', sans-serif;
    padding-top: 4%;
}

.details-info {
    background: rgba(196, 196, 196, 0.25);
    padding-left: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-top: 4.5%;
    font-family: 'Roboto', sans-serif;
}

span.details-values {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

p.location-txt {
    /* margin-left: 2%; */
    margin-top: 5%;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 20px;
}

.__Amenities_items li::before {
    position: absolute;
    content: "\2713";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #006699;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.__Amenities_items li {
    position: relative;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.__Amenities_items li span {
    margin-left: 25px;
}

.__Amenities_items {
    list-style: none;
    margin: 0;
    padding: 0;
}

p.progress-txt {
    margin-left: 2%;
    margin-top: 5%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #6A6666;
}

p.date-txt {
    margin-left: 4%;
    margin-top: 5%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #6A6666;
}

p.completed-txt {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #6A6666;
}

p.project-txt {
    margin-top: 4%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #6A6666;
    text-align: center;
}
.ads-margin-seperator{
    margin-bottom: 3% !important;
}
p.project-title {
    margin-top: 4%;
    margin-left: 1.5%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.01em;
    color: #fff !important;
}

.complete-div {
    margin-top: 5%;
    padding-top: 19px;
    padding-bottom: 5px;
    padding-left: 22px;
    padding-right: 22px;
    border: 1px solid #4A4844;
}

.progress-div {
    margin-left: 5%;
    display: inline;
}

.scroll {
    overflow-x: scroll;
}

.scrollmenu {
    overflow: auto;
    white-space: nowrap;
    padding-bottom: 4%;
}

a {
    color: #3D1F68;
    text-decoration: none;
}

a:hover {
    color: #0074A6;
    text-decoration: none;
}

.ads-betwn-margin {
    margin-bottom: 3%;
}