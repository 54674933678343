.slider_main_container{
   
    height: auto;
    padding: 100px 0px;
    background-image: linear-gradient(180deg, rgba(0, 174, 236, 0.79) 0%, rgba(0, 71, 107, 0.79) 100%), url('../../assets/background_Silder.png');
    background-size: cover; /* Ensures the image covers the entire element */
    background-position: center; /* Ensures the image is centered */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    align-content: center;
    
 .slider_container{
    
     
display: flex;

justify-content: space-evenly;
align-items: center;
    .slider_div{
        width: 60.33%;
    min-height: 540px;
    border: 19px solid white;
    border-radius: 15px;
    background-color: white;
    }
     .map_main_div{
        width: 30.33%;
        background-color: white;
        border: 19px solid white;
        border-radius: 15px;
        height: 540px;
        background-color: white;
        .detail_location{
            p{
                margin: 0px;
            }
        }
    }
 }
 

}
.carousel-indicators{
    bottom: -75px !important;
}
.carousel-indicators [data-bs-target]{
    width: 20px !important;
    border-radius: 50% !important;
    height: 20px !important;
}
*{
    box-sizing: border-box;
}



 











.carousel-item{
    width:100%;
     height: 500px; 
}





@media screen and (max-width: 700px) {
    .slider_container{
        height: 100%;
         
    display: block !important;}
    .map_main_div{
        width: 90% !important;
    height: 350px;
 margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;}


        .slider_div{
            width: 90% !important;
        height: 267px !important;
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
        }
        .carousel-item{
            width:100%;
            height:230px  
        }
  }
/* Media Query for Responsive Design */
@media screen and (max-width: 550px) {
 
  }
  