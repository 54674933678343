@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.animation-fadeInDiv {
    animation: fadeInAnimation ease 1.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 767px) {
    .content-div{
        margin-left: 0;
        margin-right: 0;
        margin-top: 25%;
        background-color: #F1F1F1;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        padding-left: 3%;
        padding-top: 6%;
        padding-right: 3%;
        padding-bottom: 3%;
        
    }
    .ads-info{
        background: linear-gradient(to right, #3D1F68 27%, #5A28A2 68%);
        padding-left: 7%;
        padding-right: 7%;
        padding-top: 5%;
        padding-bottom: 7%;
        margin-bottom: -11% !important;
    }
    .closeIconX{
        float: right;
        cursor: pointer;
        color: #fff;
        margin-right: 10%;
        margin-top: 10%;
    }
    .sideBar-mobile{
        background-color: #3D1F68;
        margin: 5%;
        padding: 5%;
        padding-top: 15%;
        border-radius: 20px;
        height: 100%;
    }
    .titleMargin-mobile{
        margin-top: 20%;
        margin-bottom: 15%;
    }
    .completeProjects-mobile{
        margin-bottom: 6.5%;
    }
    .inner-content-div{
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        padding-left: 3%;
        padding-top: 3.5%;
        padding-right: 3%;
        background-color: #fff;
    }
    .profile-pic{
        margin-top: -85px;
    }
    .btn-div{
        margin-top: 6%;
    }
    .circle2{
        display: none;
    }
    .view-pdf-btn{
        margin-top: 10px !important;
        padding: 7px;
        border: 0px solid;
        border-radius: 5px;
        background-color: #5A28A2 !important;
    }
    .view-pdf-btn:hover{
        background-color: #3D1F68 !important;
    }
    .builder-features{
        width: 100%;
        padding-left: 7%;
        padding-right: 7%;
        height: 100%;
    }
    
}
@media only screen and (min-width: 768px) {
    .builder-features{
        width: 100%;
        padding-left: 15%;
        padding-right: 15%;
    }
    .companyName-margin{
        margin-top: 4% !important;
    }
    .ads-info{
        background: linear-gradient(to right, #3D1F68 27%, #5A28A2 68%);
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 5%;
        padding-bottom: 7%;
        margin-bottom: -4% !important;
    }
    .listItems-margin{
        margin-left: 20px;
    }
    .titleMargin{
        margin-top: 10px !important;
    }
    .content-div{
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 5%;
        margin-bottom: 5%;
        background-color: #fff;
        border-radius: 30px;
        padding-left: 5%;
        padding-top: 5%;
        padding-right: 5%;
        padding-bottom: 5%;
    }
    .view-pdf-btn{
        margin-top: 50vh !important;
        padding: 7px;
        border: 0px solid;
        border-radius: 5px;
        background-color: #5A28A2 !important;
    }
    .view-pdf-btn:hover{
        background-color: #3D1F68 !important;
    }
    .btn-div{
        margin-top: 4%;
    }
    .circle2{
        width: 134px;
        height: 123px;
        float: left;
        margin-top: -60px;
        margin-left: -70px;
        border-radius: 100%;
        background: radial-gradient(185.59% 607.01% at 50% 50%, #5A28A2 0%, rgba(185, 167, 210, 0.520833) 47.92%, rgba(233, 231, 236, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        box-shadow: 9px 28px 40px 16px rgba(0, 0, 0, 0.25);
        transform: rotate(160deg);
    }
    .mobile-circle2{
        display: none;
    }
}
.__builderinfo_wrap.__builder_items {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.main{
    background-color: #3D1F68;
    overflow: hidden;
    max-height: 200%;
}

.circle{
    width: 134px;
    height: 123px;
    float: right;
    margin-top: -40px;
    margin-right: -60px;
    border-radius: 100%;
    background: radial-gradient(185.59% 607.01% at 50% 50%, #5A28A2 0%, rgba(185, 167, 210, 0.520833) 47.92%, rgba(233, 231, 236, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    box-shadow: 9px 28px 40px 16px rgba(0, 0, 0, 0.25);
}
.shadow{
    border:2px solid #fff;
    -moz-box-shadow: 0px 6px 5px #ccc;
    -webkit-box-shadow: 0px 6px 5px #ccc;
    box-shadow: 0px 6px 5px #ccc;
    -moz-border-radius:190px;
    -webkit-border-radius:190px;
    border-radius:190px;
}
.message-txt{
    padding-top: 4%;
    font-family: 'Roboto', sans-serif;
}

button.message-btn {
    background-color: #fff;
    border: none;
    color: #3D1F68;
    padding: 7px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    border: 2px solid #3D1F68;
    margin-right: 1%;
}
button.contact-btn {
    background-color: #3D1F68;
    border: none;
    color: #fff;
    padding: 7px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    margin-left: 1%;
    border: 2px solid #3D1F68;
}
p.advt-txt{
    padding-top: 20px;
    padding-bottom: 7px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #AE9A9A;
    cursor: pointer;
}
th{
    font-family: 'Roboto', sans-serif;
    padding-top: 4%;
}
.info{
    background: rgba(196, 196, 196, 0.25);
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 3%;
    padding-bottom: 0.5%;
    margin-top: 4.5%;
    font-family: 'Roboto', sans-serif;
    border-radius: 15px;
}
.__builder_item.__builderinfo_left img {
    border-radius: 50%;
}
.__builder_map_info {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 40px 0px;
}

.__builder_approved_banks_info {
    display: flex;
    align-items: center;
    margin: 40px 0px;
    flex-direction: column;
}

.__builder_floorplan_info {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 40px 0px;
}

.__builder_floorplan_info img {
    max-width: 100%;
    height: 603px;
}

.__project_random_text {
    display: flex;
    justify-content: normal;
    align-items: center;
    max-width: 920px;
    margin: auto;
}
.__proccess_project_items {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.__proccess_project_item img {
    width: 60%;
}

.__proccessof_projects {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.__proccess_project_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.__proccess_project_item a {
    margin-top: 20px;
    padding: 13px 50px;
    background: grey;
    color: #fff;
    border-radius: 8px;
}
.__completect_project_cta h2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 25px 0px;
    background: grey;
    max-width: 80%;
    margin: 6% auto;
    color: #fff;
    border-radius: 10px;
}
.__photos_of_projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0px;
}

.__photos_of_project_items {
    display: flex;
    gap: 15px;
}

.__photos_of_project_items img {
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
}

.__photos_of_projects h2 {
    margin-bottom: 32px;
    border-bottom: 1px solid;
    line-height: 39px;
}
p.progress-txt-builder {
    font-style: normal;
    letter-spacing: 0.01em;
    text-align: center;
    margin-bottom: 7%;
    font-weight: 700;
    padding-bottom: 0;
    color: #3D1F68;
    font-size: 40px;
    line-height: 60px;
}
p.progress-txt-builder-white {
    font-style: normal;
    letter-spacing: 0.01em;
    text-align: center;
    margin-bottom: 7%;
    font-weight: 700;
    padding-bottom: 0;
    color: #fff;
    font-size: 40px;
    line-height: 60px;
}
.doubleTick-icon{
    color: #3D1F68;
    margin-right: 7px;
}
.doubleTick-icon-white{
    color: #fff;
    margin-right: 7px;
}
.builder-img-margins{
    width: 100%;
    height: 90%;
    margin-right: 5%;
}
.hightlt-txt{
    color: #fff;
    text-align: left;
}
.builderHero{
    background: linear-gradient(to right, #3D1F68 27%, #5A28A2 68%);
}
.layout-shadow{
    box-shadow: 0px 18px 26px rgb(74 63 63 / 25%);
    padding: 5px;
    border-radius: 15px;
}
.ads-margin-less{
    margin-bottom: 6% !important;
    margin-top: -2% !important;
}