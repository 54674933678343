.bg-gradient {
    

    background-image: linear-gradient(109.52deg, rgba(254, 183, 32, 0.69) 9.86%, rgba(38, 92, 113, 0.83) 49.81%, rgba(0, 71, 107, 0.76) 63.69%, rgba(0, 71, 107, 0.79) 70.89%, rgba(0, 71, 107, 0.84) 92.08%, rgba(115, 115, 115, 0.86) 128.77%), url('../../assets/background_Silder.png') !important;
    background-size: cover; /* Ensures the image covers the entire element */
    background-position: center; /* Ensures the image is centered */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    align-content: center;
  }
 
.upper-div{

.fw-semibold{
    font-family: Manrope;
font-size: 24px;
font-weight: 800;
line-height: 32.78px;
 
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FCFCFC;

}

p{
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.78px;
   
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    
}}

.backdrop-blur{
    
height: 265px;
box-shadow: 2px -3px 8px -1px #D9E9EF;

 color: black !important;
border: 13px !important;
    background-image: url(../../assets/Color-Triangle-51.png),linear-gradient(#B4D4D4,#ecf2f2);
   background-repeat: no-repeat;
   background-size: cover;

}

.inner-div{
    h3{
        font-family: Manrope;
font-size: 21px;
font-weight: 800;
line-height: 28.69px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #090808;

    }
    span{
        font-family: Manrope;
font-size: 18px;
font-weight: 700;
line-height: 24.59px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

color: #090808;


    }
}

.inner-sub-div{
  margin: 0 auto;
    box-shadow: 0px 4px 4px 0px #1C3E6A75;
border-radius: 10px;
max-width: 178px;
height: 116px;
top: 1933px;
left: 81px;
gap: 0px;
border-radius: 10px 0px 0px 0px;


}

.bg-info{
    background: #95DEFF !important;

}
.bg-success{
    background: #CDF6BB !important;

}
.bg-light{
    background: #A2CCC6 !important;

}
.bg-warning{
    background: #FFD58D !important;

}
 