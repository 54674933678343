body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-ms-overflow-style: none; /** IE11 */
	overflow-y: scroll;
	overflow-x: hidden;
	margin-right: -20px;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}
::-webkit-scrollbar {display:none;}
@-moz-document url-prefix() {
  html,
  body {
    scrollbar-width: none;
  }
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


.__proccessof_projects img,
.project_image img,
.__floorplans_projects img {
	max-width: 100%;
}

.__proccessof_projects img {
	height: 320px;
	width: 320px;
	object-fit: cover;
}

.__ProjectDetailsitems div {
	width: 30.33%;
	box-shadow: 0 0 3px #fff;
	background: #fff;
	padding: 34px 25px;
}

.__ProjectDetailsitems {
	position: relative;
}

/* .__ProjectDetailsitems::after {
	position: absolute;
	width: 92.99%;
	content: "";
	height: 3px;
	background: #006699;
	bottom: 0px
} */

.adIDposabs {
	position: absolute;
	top: -1px;
	left: -1px;
	background: #006699;
	padding: 14px 25px;
	border-radius: 32px;
	color: #fff;
	transform: rotate(359deg);
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	border-bottom-left-radius: 0;
	border-top-right-radius: 0;
}

.zoom-in-out-box {
	background: transparent;
	animation: zoom-in-zoom-out 1s ease infinite;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	width: 100%;
	height: 100vh;
	font-size: 70px;
	opacity: 0.6;
}

@keyframes zoom-in-zoom-out {
	0% {
		transform: scale(1, 1);
	}

	50% {
		transform: scale(1.5, 1.5);
	}

	100% {
		transform: scale(1, 1);
	}
}