$sidebar-bg-color: #4A4844 !default;
$sidebar-color: #fff !default;
$icon-bg-color: #264752 !default;
$sidebar-width: 300px !default;
$highlight-color: #e0e0e0 !default;
$sidebar-collapsed-width: 0px !default;
$submenu-bg-color: #938D8D !default;
$submenu-indent: 38px !default;
$submenu-bg-color-collapsed: #264752 !default;
$icon-size: 38px !default;
$submenu-indent: 30px !default;

@import '~react-pro-sidebar/dist/scss/styles.scss';