.min-vh-100s{
     
    min-height: 328px;
    top: 3535px;
    left: -5px;
    gap: 0px;
    border-radius: 18px 0px 0px 0px;
    padding: 44px 0px;
    background: linear-gradient(100.3deg, rgba(255, 240, 162, 0.34) 37.21%, rgba(0, 174, 236, 0.1088) 78.06%);
display: flex;
align-items: center;

    
}

.bank-card{
    width: 287px;
height: 293.32px;
background: #FFDB91 !important;
border-radius: 50% 50% 0% 0% !important;

}
.apply-btn{
    width: 232px;
height: 54.43px;
 
border-radius: 41px ;
 

font-family: Manrope;
font-size: 24px;
font-weight: 800;
line-height: 32.78px;
 
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: black;


}
.bank-heading{
    font-family: Manrope;
font-size: 24px;
font-weight: 800;
line-height: 32.78px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: black;
}